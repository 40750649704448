/* This makes the "Logout" and "Login" buttons look like links */
.link-button {
    background: none;
    color: #27358c;
    border: none;
    padding: 0;
    font: inherit;
    outline: 0;
    border-bottom: 0px solid #444;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    padding-left: 20px;
    font-family: "Lato", "Arial", "Helvetica", sans-serif;
    font-weight: 700;
}
.navbar__link {
    background: none;
    color: #27358c;
    border: none;
    padding: 0;
    font: inherit;
    outline: 0;
    border-bottom: 0px solid #444;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    padding-left: 20px;
    font-family: "Lato", "Arial", "Helvetica", sans-serif;
    font-weight: 700;
}

.navbar__link:hover {
    transform: translateY(-2px);
}

.navbar__link--active {
    border-bottom: 3px solid #29b6f6;
    transition: border-bottom 0.5s ease-in-out;
}
